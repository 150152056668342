import React, { InputHTMLAttributes } from "react";
import { clsx } from "clsx";

interface InputPropType extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  error?: string;
  showsErrorMessage?: boolean;
  uppercase?: boolean;
  dark?: boolean;
  inputStyle?: "rounded" | "capsule";
  ref?: React.Ref<HTMLInputElement>;
}

const CapsuleInput: React.FC<InputPropType> = React.forwardRef<
  HTMLInputElement,
  InputPropType
>(
  (
    {
      label,
      labelClassName,
      error,
      showsErrorMessage = true,
      uppercase = false,
      dark = false,
      inputStyle,
      ...props
    },
    ref
  ) => {
    return (
      <div className="flex flex-col items-start w-full">
        <div className="relative w-full">
          <input
            className={clsx(
              `h-16 block px-7 pb-2.5 pt-4 w-full text-base bg-inherit border rounded-full border-solid focus:outline-none focus:ring-0 peer`,
              error
                ? "border-alter-error hover:border-alter-error placeholder-shown:border-alter-error focus:border-alter-error"
                : dark
                  ? "border-alter-bone hover:border-alter-bone placeholder-shown:border-alter-bone focus:border-alter-bone text-alter-bone"
                  : "border-alter-black hover:border-alter-black placeholder-shown:border-alter-black-20 focus:border-alter-black text-alter-black",
              uppercase && "uppercase",
              "placeholder:opacity-0 focus:placeholder:opacity-100 placeholder:duration-300"
            )}
            {...props}
            id={label}
            ref={ref}
            placeholder={props.placeholder ?? " "}
          />
          {label && (
            <label
              htmlFor={label}
              className={clsx(
                `absolute text-base px-2  duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] peer-placeholder-shown:bg-inherit`,
                dark
                  ? `peer-focus:bg-alter-black bg-alter-black`
                  : `peer-focus:bg-alter-bone bg-alter-bone`,
                "peer-placeholder-shown:scale-100  peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-6",
                error
                  ? "text-alter-error peer-focus:text-alter-error peer-placeholder-shown:text-alter-error"
                  : dark
                    ? "text-alter-bone peer-focus:text-alter-bone peer-placeholder-shown:text-alter-bone"
                    : "text-alter-black peer-focus:text-alter-black peer-placeholder-shown:text-alter-black-20",
                labelClassName
              )}
            >
              {label}
            </label>
          )}
        </div>
        {showsErrorMessage && error && (
          <div className="px-7 h-fit" role="alert">
            <p className="text-alter-error text-xs text-left whitespace-pre-wrap">
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);

CapsuleInput.displayName = "CapsuleInput";

const RoundedInput: React.FC<InputPropType> = React.forwardRef<
  HTMLInputElement,
  InputPropType
>(
  (
    {
      label,
      labelClassName,
      error,
      showsErrorMessage = true,
      uppercase = false,
      dark = false,
      inputStyle,
      ...props
    },
    ref
  ) => {
    return (
      <div className="flex flex-col items-start w-full">
        <div className="relative w-full flex flex-col gap-2">
          {label && (
            <label
              htmlFor={label}
              className={clsx(`text-sm text-alter-black-70`, labelClassName)}
            >
              {label}
            </label>
          )}
          <input
            style={{ WebkitAppearance: "none", MozAppearance: "none" }}
            className={clsx(
              `text-left h-14 px-4 w-full text-base font-light bg-alter-black-4`,
              `border rounded-2xl border-solid focus:outline-none`,
              `placeholder-text-alter-black-60`,
              error ? "border-alter-error" : "border-alter-black-10",
              uppercase && "uppercase"
            )}
            {...props}
            id={label}
            ref={ref}
            placeholder={props.placeholder ?? " "}
          />
        </div>
        {showsErrorMessage && error && (
          <div className="h-fit" role="alert">
            <p className="text-alter-error text-xs text-left whitespace-pre-wrap">
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);

RoundedInput.displayName = "RoundedInput";

const Input: React.FC<InputPropType> = React.forwardRef<
  HTMLInputElement,
  InputPropType
>(
  (
    {
      label,
      labelClassName,
      error,
      showsErrorMessage = true,
      uppercase = false,
      dark = false,
      inputStyle = "capsule",
      ...props
    },
    ref
  ) => {
    switch (inputStyle) {
      case "rounded":
        return (
          <RoundedInput
            label={label}
            labelClassName={labelClassName}
            error={error}
            showsErrorMessage={showsErrorMessage}
            uppercase={uppercase}
            dark={dark}
            {...props}
            ref={ref}
          />
        );
      default:
        return (
          <CapsuleInput
            label={label}
            labelClassName={labelClassName}
            error={error}
            showsErrorMessage={showsErrorMessage}
            uppercase={uppercase}
            dark={dark}
            {...props}
            ref={ref}
          />
        );
    }
  }
);

Input.displayName = "Input";

export default Input;
